// src/pages/Feedback.tsx
import React from 'react';

const Feedback: React.FC = () => {
  return (
    <div className="feedback">
      <h1>Feedback</h1>
      <p>We value your feedback. Please let us know your thoughts.</p>
      {/* Add a feedback form or contact details */}
    </div>
  );
};

export default Feedback;
