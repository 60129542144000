// src/utils/fetchTokenBalance.ts

import { Connection, PublicKey, ParsedAccountData } from '@solana/web3.js';

/**
 * Type guard to check if the provided data is ParsedAccountData.
 *
 * @param data - The data to check.
 * @returns True if data is ParsedAccountData, false otherwise.
 */
export function isParsedAccountData(data: any): data is ParsedAccountData {
  return (
    data &&
    typeof data === 'object' &&
    'program' in data &&
    'parsed' in data &&
    'space' in data
  );
}

/**
 * Fetches the balance of a specific SPL token for a given wallet.
 *
 * @param connection - The Solana connection object.
 * @param owner - The public key of the wallet owner.
 * @param mintAddress - The mint address of the SPL token.
 * @returns The total token balance as a number.
 */
export const fetchTokenBalance = async (
  connection: Connection,
  owner: PublicKey,
  mintAddress: PublicKey
): Promise<number> => {
  try {
    // Fetch parsed token accounts by owner and mint address
    const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
      owner,
      {
        mint: mintAddress,
      }
    );

    console.log(
      `Found ${tokenAccounts.value.length} token account(s) for mint ${mintAddress.toBase58()}:`
    );
    tokenAccounts.value.forEach((tokenAccount, index) => {
      console.log(`Token Account ${index + 1}: ${tokenAccount.pubkey.toBase58()}`);
    });

    let totalBalance = 0;

    tokenAccounts.value.forEach((tokenAccount) => {
      const data = tokenAccount.account.data;
      if (isParsedAccountData(data)) {
        const uiAmount = data.parsed.info.tokenAmount.uiAmount;
        console.log(
          `Token Account: ${tokenAccount.pubkey.toBase58()} - Balance: ${uiAmount} OHIO`
        );
        if (uiAmount) {
          totalBalance += uiAmount;
        }
      } else {
        console.log(
          `Token Account: ${tokenAccount.pubkey.toBase58()} - Data is not ParsedAccountData`
        );
      }
    });

    return totalBalance;
  } catch (error) {
    console.error('Error fetching token balance:', error);
    throw error;
  }
};
