import React from 'react';
import styles from '../styles/BusinessPage.module.css';

const BusinessPage: React.FC = () => {
  return (
    <main className={styles.businessPage}>
      <h1 className={styles.title}>Biz in OHIO</h1>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Ohio's Career Navigator</h2>
        <p className={styles.text}>
          Ohio's Career Navigator is straight-up sigma-level, giving you personalized resources to mog your way to
          success. Whether you're looking to skibidi into a new job or level up your current gig, this tool’s got you
          covered. It’s all about guiding you with the right mew steps and connections to reach your goals. Get on that
          fanum grind and let Ohio's Career Navigator be your edge in the career game.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Unemployment Benefits</h2>
        <p className={styles.text}>
          In Ohio, unemployment benefits are that mew safety net for workers who got mogged from their jobs without
          doing anything wrong. These benefits give you some sigma cash flow while you're out there on that skibidi
          grind looking for new work. It's all about helping you stay afloat and bounce back stronger. So, if you’re
          hustling to get back in the game, Ohio's got your back with those fanum unemployment benefits.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Ohio Means Jobs</h2>
        <p className={styles.text}>
          Ohio Means Jobs is your sigma-tier spot to skibidi into job searching or get fanum guidance on employment and
          training services. Whether you're hunting for the next gig or looking to up your skills, OhioMeansJobs has
          the resources to help you mew right into the workforce. Let them guide you and mog that career path like a
          boss!
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Find a Career</h2>
        <p className={styles.text}>
          Looking to mew up your career with the State of Ohio? You're in luck because Ohio's a sigma model employer for
          skills-based hiring. They prioritize your actual abilities over just paper credentials, helping you skibidi
          right into a role that matches your talents. It’s all about finding the right fit and mogging those job
          opportunities with the state. Fanum vibes all around!
        </p>
      </section>
    </main>
  );
};

export default BusinessPage;
