// src/components/WalletConnector.tsx
import React, {useEffect, useState } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import '../styles/overrides.css';  

const WalletConnector: React.FC = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const sendSol = async () => {
    if (!publicKey) {
      alert('Please connect your wallet first!');
      return;
    }

    const recipient = new PublicKey('RecipientPublicKeyHere');
    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: recipient,
        lamports: 1000000, // Amount in lamports (1 SOL = 1,000,000,000 lamports)
      })
    );

    try {
      const signature = await sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      alert('Transaction Successful!');
    } catch (error) {
      console.error(error);
      alert('Transaction Failed!');
    }
  };

  return (
    <div>
      <WalletMultiButton />
    </div>
  );
};

export default WalletConnector;
