// src/pages/Forms.tsx
import React from 'react';


const Forms: React.FC = () => {
  return (
    <div className="forms">
      <h1>Online Forms</h1>
      <ul>
        <li><a href="/boat-registration">Boat Registration</a></li>
        <li><a href="/dolphin-project">Dolphin Project</a></li>
        <li><a href="/pay-taxes">Pay Taxes by Credit Card</a></li>
        {/* Add more forms as needed */}
      </ul>
    </div>
  );
};

export default Forms;
