// src/pages/Privacy.tsx
import React from 'react';

const Privacy: React.FC = () => {
  return (
    <div className="privacy">
      <h1>Privacy Policy</h1>
      <p>Your privacy is important to us. Here's how we handle your data.</p>
      {/* Add detailed privacy policy content */}
    </div>
  );
};

export default Privacy;
