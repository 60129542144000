// src/components/WalletBalances.tsx

import React, { useEffect, useState } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
// import { PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { fetchSolBalance } from '../utils/fetchSolBalance';
import { fetchTokenBalance } from '../utils/fetchTokenBalance';
import { OHIO_TOKEN_MINT } from '../config/tokenConfig';
import styles from './WalletBalances.module.css'; // Ensure this CSS module exists

const WalletBalances: React.FC = () => {
  const { connection } = useConnection();
  const { publicKey } = useWallet();
  const [solBalance, setSolBalance] = useState<number | null>(null);
  const [ohioBalance, setOhioBalance] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!publicKey) {
      setSolBalance(null);
      setOhioBalance(null);
      setError(null);
      return;
    }

    const fetchBalances = async () => {
      setLoading(true);
      setError(null);
      try {
        // Fetch SOL Balance using getBalance
        const balanceSol = await fetchSolBalance(connection, publicKey);
        console.log(`Fetched SOL Balance: ${balanceSol} SOL`);
        setSolBalance(balanceSol);

        // Fetch OHIO Token Balance using getTokenAccountsByOwner
        const balanceOHIO = await fetchTokenBalance(connection, publicKey, OHIO_TOKEN_MINT);
        console.log(`Fetched OHIO Balance: ${balanceOHIO} OHIO`);
        setOhioBalance(balanceOHIO);
      } catch (err) {
        console.error('Error fetching balances:', err);
        setError('Failed to fetch balances.');
        setSolBalance(null);
        setOhioBalance(null);
      }
      setLoading(false);
    };

    fetchBalances();
  }, [publicKey, connection]);

  if (!publicKey) {
    return (
      <div className={styles.walletBalances}>
        <p>Connect your wallet to see balances.</p>
      </div>
    );
  }

  return (
    <div className={styles.walletBalances}>
      {loading ? (
        <div className={styles.loading}>
          <div className={styles.spinner}></div>
          <p>Loading balances...</p>
        </div>
      ) : (
        <>
          {error && <p className={styles.error}>{error}</p>}
          {!error && (
            <>
              <div className={styles.balanceItem}>
                <span className={styles.balanceLabel}>SOL Balance:</span>
                <span className={styles.balanceValue}>
                  {solBalance !== null ? `${solBalance.toFixed(2)} SOL` : 'N/A'}
                </span>
              </div>
              <div className={styles.balanceItem}>
                <span className={styles.balanceLabel}>OHIO Balance:</span>
                <span className={styles.balanceValue}>
                  {ohioBalance !== null ? `${ohioBalance.toFixed(2)} OHIO` : 'N/A'}
                </span>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default WalletBalances;
