// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import WalletContext from './WalletContext';
import './styles/global.css'; // Importing global styles
import { Buffer } from 'buffer';
import process from 'process';

(window as any).Buffer = Buffer;
(window as any).process = process;

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container); // Correct usage

root.render(
  <React.StrictMode>
    <WalletContext>
      <App />
    </WalletContext>
  </React.StrictMode>
);