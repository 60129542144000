// src/components/SecondaryNavigation/SecondaryNavigation.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './SecondaryNavigation.module.css';

const SecondaryNavigation: React.FC = () => {
  return (
    <nav className={styles.secondaryNav}>
      <ul>
        <li><Link to="/rizzident">Rizzident</Link></li>
        <li><Link to="/Business">Business</Link></li>
        <li><Link to="/visitor">Visitor</Link></li>
        <li><Link to="/government">Government</Link></li>
      </ul>
    </nav>
  );
};

export default SecondaryNavigation;
