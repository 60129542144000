import React from 'react';
import styles from '../styles/ExploreOhio.module.css';
import ohioMapImage from '../assets/images/OHIO-state.png';
import { Link } from 'react-router-dom';

const ExploreOhioPage: React.FC = () => {
  return (
    <main className={styles.exploreOhioPage}>
      <h1 className={styles.title}>Explore Ohio</h1>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Ohio Brain Rot Map</h2>
        <p className={styles.text}>
          Fanum, check out Ohio’s edge attractions, lit events, dope lodging, and all the skibidi things to do and more! There's a mew for everyone to vibe with.
        </p>
        <div className={styles.mapContainer}>
        <img 
            src={ohioMapImage} 
            alt="Ohio Brain Rot Map" 
            className={styles.mapImage} 
          />
        </div>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Maps & Traffic</h2>
        <p className={styles.text}>
          Explore sigma spots in Ohio, plan your route, and skibidi what you'll encounter along the way. From scenic byways to real-time traffic updates, Ohio's got you covered.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Arts</h2>
        <p className={styles.text}>
          Ohio's got vibrant arts scenes, cultural vibes for every taste, rich historical mews, and much more! From galleries to festivals, Ohio’s arts and culture are a must-see.
          <Link to="/arts" className={styles.artsLink}>
            Learn more about Ohio's Arts & Culture
          </Link>
        </p>
      </section>
    </main>
  );
};

export default ExploreOhioPage;
