// src/pages/Search.tsx
import React from 'react';

const Search: React.FC = () => {
  return (
    <div className="search">
      <h1>Search</h1>
      <form>
        <input type="text" placeholder="Search..." className="searchInput" />
        <button type="submit" className="searchButton">Go</button>
      </form>
    </div>
  );
};

export default Search;
