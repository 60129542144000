// src/pages/Contacts.tsx
import React from 'react';

const Contacts: React.FC = () => {
  return (
    <div className="contacts">
      <h1>Contact Us</h1>
      <p>For inquiries, please reach out through the following channels:</p>
      <ul>
        <li>Email: contact@ohio.gov</li>
        <li>Phone: (614) 645-5000</li>
        {/* Add more contact methods as needed */}
      </ul>
    </div>
  );
};

export default Contacts;
