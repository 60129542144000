// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import SecondaryNavigation from './components/SecondaryNavigation/SecondaryNavigation';
import Sidebar from './components/Sidebar/Sidebar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import Forms from './pages/Forms';
import Contacts from './pages/Contacts';
import PressRoom from './pages/PressRoom';
import Search from './pages/Search';
import Privacy from './pages/Privacy';
import Feedback from './pages/Feedback';
import SwapPage from './pages/swap';
import BusinessPage from './pages/BusinessPage';
import RizzidentPage from './pages/RizzidentPage';
import VisitorPage from './pages/VisitorPage';
import DMVPage from './pages/subpages/DMVPage';
import GooningParksPage from './pages/subpages/GooningParksPage';
import ExploreOhioPage from './pages/ExploreOhio';
import Arts from './pages/Arts';
import Government from './pages/Government';
import './styles/global.css';

const App: React.FC = () => {
  return (
    <Router>
      <Header />
      <SecondaryNavigation />
      <div className="container">
        <Sidebar />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/forms" element={<Forms />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/pressroom" element={<PressRoom />} />
            <Route path="/search" element={<Search />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/swap" element={<SwapPage />} /> {/* Use <SwapPage /> */}
            <Route path="/business" element={<BusinessPage />} />
            <Route path="/rizzident" element={<RizzidentPage />} />
            <Route path="/visitor" element={<VisitorPage />} />
            <Route path="/subpages/DMVPage" element={<DMVPage />} />
            <Route path="/subpages/Gooning-parks" element={<GooningParksPage />} />
            <Route path="/explore-ohio" element={<ExploreOhioPage />} />
            <Route path="/arts" element={<Arts />} />
            <Route path="/government" element={<Government />} />
          </Routes>
        </main>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
