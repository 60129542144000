import React from 'react';

const SwapPage: React.FC = () => {
  const solMintAddress = 'So11111111111111111111111111111111111111112'; // SOL Mint
  const ohioMintAddress = '8VRfgKJY5CnfgGkstisB3QgLPWM6xYBK4esrfN5Hpump'; // OHIO Mint

  const raydiumUrl = `https://raydium.io/swap?inputToken=${solMintAddress}&outputToken=${ohioMintAddress}`;

  return (
    <div className="swap-page">
      <h1>Swap SOL to OHIO</h1>
      <div className="swap-widget-container">
        <iframe
          src={raydiumUrl}
          title="Raydium Swap"
          className="swap-widget"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default SwapPage;
