import React from 'react';
import styles from '../styles/Government.module.css';

const Government: React.FC = () => {
  return (
    <div className={styles.governmentPageContent}>
    <div className={styles.governmentPage}>
      <h1 className={styles.pageTitle}>The Grand Sigma of Ohio</h1>
      <p className={styles.intro}>
        Yo fam, this is where the fanum grind meets government vibes. Ohio's governance is straight-up mogging the scene, led by the ultimate <strong>Grand Sigma of Ohio</strong> – our fearless and charismatic leader who keeps it 100 while flexing those sigma-level moves. Dive into how we’re skibidi-ing through laws, taxes, and everything else that makes Ohio the GOAT.
      </p>

      <section className={styles.declarationSection}>
        <h2 className={styles.sectionTitle}>The Declaration of Ohio-Pendence (Brainrot Edition)</h2>
        <p className={styles.sectionText}> 
          <em>
            “We the Sigmas of Ohio, in pursuit of mogging the game and living our best lives, declare that <strong>every Ohioan</strong> deserves the right to rizz, vibe, and skibidi through life without mutt-tier nonsense. The government here is built different, providing that sweet, fanum-level support to keep the hustle alive.”
          </em>
        </p>
        <ul className={styles.declarationPoints}>
          <li>🔥 Keeping it sigma with low taxes and high vibes.</li>
          <li>🔥 Supporting Ohioans on their career skibidi grind.</li>
          <li>🔥 Mogging bureaucracy so you can do you, fam.</li>
        </ul>
      </section>

      <section className={styles.grandSigmaMessage}>
        <h2 className={styles.sectionTitle}>Grand Sigma's Message</h2>
        <blockquote className={styles.quote}>
          “My vision for Ohio is simple: Keep the mutts out, the vibes high, and the people thriving. We’re building the sigma capital of the world, one rizz-filled law at a time. Whether you're out there chasing gyatt moments or grinding for the bag, Ohio’s got your back.”
          <span className={styles.signature}>- The Grand Sigma, Kai Cenat</span>
        </blockquote>
      </section>

      <section className={styles.governmentMoves}>
        <h2 className={styles.sectionTitle}>Government Moves</h2>
        <ul>
          <li>
            <strong>Taxes (Mew $$$ Moves):</strong> Fair rates and mew refunds ensure Ohio stays sigma-friendly. The Fanum Tax Program has your back!
          </li>
          <li>
            <strong>State Jobs (Skibidi Grind Opportunities):</strong> Sigma-tier jobs with benefits and skills-based hiring for the real mogs out there.
          </li>
          <li>
            <strong>Infrastructure (Sigma Streets and Skibidi Roads):</strong> Ohio Gyattway System keeps you moving and ahead of the game.
          </li>
          <li>
            <strong>Democracy (Mew the Vote):</strong> Empowering Ohioans to make their voices heard, one fanum vote at a time.
          </li>
        </ul>
      </section>

      <section className={styles.featuredContent}>
        <h2 className={styles.sectionTitle}>Featured Content</h2>
        <ul>
          <li>
            <a href="https://ohio.gov/fanum-tax" target="_blank" rel="noopener noreferrer">
              Fanum Tax Info
            </a>
          </li>
          <li>
            <a href="https://jobs.ohio.gov" target="_blank" rel="noopener noreferrer">
              Jobs in Ohio
            </a>
          </li>
          <li>
            <a href="https://ohio.gov/grandsigma-vision" target="_blank" rel="noopener noreferrer">
              Grand Sigma Kai Cenat’s Vision
            </a>
          </li>
        </ul>
      </section>
    </div>
    </div>
  );
};

export default Government;
