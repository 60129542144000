// src/components/Footer/Footer.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.navLinks}>
        <Link to="/">Home</Link> / 
        <Link to="/forms">Forms</Link> / 
        <Link to="/contacts">Contacts</Link> / 
        <Link to="/pressroom">Press Room</Link> / 
        <Link to="/feedback">Feedback</Link> / 
        <Link to="/privacy">Privacy Policy</Link>
      </div>
      <div className={styles.decorations}>
        <div className={styles.leftDecoration}></div>
        <div className={styles.rightDecoration}></div>
      </div>
    </footer>
  );
};

export default Footer;
