import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './MainContent.module.css';
import Dlogo from '../../assets/images/BrainrotOhio_circle.png';
import NEHFW from '../../assets/images/flag1-trans.gif';
import publicService from '../../assets/images/OHIO-state.png';

const MainContent: React.FC = () => {
  const [squareImage, setSquareImage] = useState<string | null>(null);

  const handleMouseOver = (image: string) => {
    setSquareImage(image);
  };

  const handleMouseOut = () => {
    setSquareImage(null);
  };

  const features = [
    {
      name: 'Ohio Arts',
      link: '/Arts',
      image: Dlogo,
    },
    {
      name: 'OHIGHER',
      link: 'https://raydium.io/swap/?inputMint=sol&outputMint=8VRfgKJY5CnfgGkstisB3QgLPWM6xYBK4esrfN5Hpump',
      image: NEHFW,
    },
    {
      name: 'SKIBIDI Park',
      link: '/subpages/Gooning-Parks',
      image: publicService,
    },
  ];

  return (
    <main className={styles.mainContent}>
      <div className={styles.leftColumn}>
        <h2 className="features-title">FEATURES:</h2>
        <ul className="features-list">
          {features.map((feature, index) => {
            const isExternal = feature.link.startsWith('http');
            return (
              <li key={index}>
                {isExternal ? (
                  <a
                    href={feature.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseOver={() => handleMouseOver(feature.image)}
                    onMouseOut={handleMouseOut}
                  >
                    {feature.name}
                  </a>
                ) : (
                  <Link
                    to={feature.link}
                    onMouseOver={() => handleMouseOver(feature.image)}
                    onMouseOut={handleMouseOut}
                  >
                    {feature.name}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </div>

      {squareImage && (
        <div className={styles.squareImageContainer}>
          <img src={squareImage} alt="Interactive" className={styles.squareImage} />
        </div>
      )}

      <div className={styles.rightColumn}>
        Alright, let's dive into the Ohio grindset, peep the government setup, programs, and the whole service vibe.
      </div>
    </main>
  );
};

export default MainContent;
