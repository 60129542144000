// src/pages/PressRoom.tsx
import React from 'react';

const PressRoom: React.FC = () => {
  return (
    <div className="pressRoom">
      <h1>Press Room</h1>
      <p>Latest news and updates from the State of Ohio.</p>
      {/* Add press releases, media contacts, etc. */}
    </div>
  );
};

export default PressRoom;
