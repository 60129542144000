// src/utils/fetchSolBalance.ts
import { Connection, PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';


/**
 * Fetches the SOL balance for a given wallet.
 *
 * @param connection - The Solana connection object.
 * @param walletAddress - The public key of the wallet.
 * @returns The SOL balance as a number.
 */
export const fetchSolBalance = async (
  connection: Connection,
  walletAddress: PublicKey
): Promise<number> => {
  try {
    const balanceLamports = await connection.getBalance(walletAddress);
    const balanceSol = balanceLamports / LAMPORTS_PER_SOL;
    return balanceSol;
  } catch (error) {
    console.error('Error fetching SOL balance:', error);
    throw error;
  }
};
