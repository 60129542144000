import React from 'react';
import styles from '../styles/Arts.module.css';

const Arts: React.FC = () => {
  return (
    <div className={styles.artsPage}>
      <h1 className={styles.pageTitle}>Arts & Culture Here in Ohio</h1>
      <div className={styles.contentSection}>
        <p>
          <strong>Vibe-heavy Linguo:</strong> Brainrot talk is all about hitting those playful, meme-fueled vibes that crank up the hype. It’s like taking your everyday chat and remixing it with a more lit and expressive twist.
        </p>
        <p>
          <strong>Saucy Rephrasing:</strong> It’s all about reworking common sayings and ideas with a unique, sometimes extra spin. The mission? Keep the lingo fresh and entertaining, often pulling in that spicy online slang or pop culture drip.
        </p>
        <p>
          <strong>Chill and Relatable Flow:</strong> Brainrot is all about that laid-back, fun, and super relatable vibe. It's not about being all formal; it's about connecting like you're in on the same inside joke, adding a dash of humor and personality.
        </p>
      </div>
    </div>
  );
};

export default Arts;
