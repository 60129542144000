// src/components/Header/Header.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import ohiogovLogo from '../../assets/images/rotohiogov.jpg';
import ohioLogo from '../../assets/images/ohio.gif';
import WalletConnector from '../../components/WalletConnector';

const Header: React.FC = () => {
  return (
    <header className={styles.header}>
     
      <div className={styles.logoContainer}>
        <Link to="/">
          <img src={ohiogovLogo} alt="Ohio Government" className={styles.logo} />
        </Link>
      </div>
      <div className={styles.ohioLogoContainer}>
        <img src={ohioLogo} alt="Ohio" className={styles.ohioLogo} />
        
      </div>
      <div className={styles.walletconnect}><WalletConnector /></div>
    </header>
  );
};

export default Header;
