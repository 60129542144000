import React from 'react';
import styles from '../../styles/GooningParksPage.module.css';

const GooningParksPage: React.FC = () => {
  return (
    <main className={styles.gooningParksPage}>
      <h1 className={styles.title}>Activities</h1>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Edging</h2>
        <p className={styles.text}>
          Edging in brainrot terms is all about that hype, fam. It's like when you're riding that high of anticipation, feeling the thrill without hitting the climax just yet. You're staying on that edge, teetering on the brink, soaking in the tension and excitement without letting it tip over into full-blown chaos. It's the ultimate tease, keeping things intense and exhilarating without going all the way.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Gooning</h2>
        <p className={styles.text}>
          Gooning in brainrot speak is all about getting lost in the sauce, fam. It's when you're deep in that zone, totally immersed, and vibing with whatever's got you hooked. You're in a trance, fully consumed by the experience, and just letting it take over. It's that next-level obsession where you're not just edging but completely surrendering to the flow and letting it mog your mind.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Rizz</h2>
        <p className={styles.text}>
          Rizz in brainrot terms is your ultimate charisma flex, fam. It's that slick, smooth charm that makes you stand out and mog everyone. With rizz, you’re the sigma, effortlessly pulling people in with your vibe. It’s the art of talking your way into anyone’s good graces. You’re not just in the game; you’re fanum level, skibidi-ing your way to the top.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Gyatt</h2>
        <p className={styles.text}>
          Gyatt in brainrot terms is that instant jaw-drop reaction, fam. It’s the moment when you see something or someone so impressive, it leaves you speechless. It's that pure awe and admiration hitting you out of nowhere, making you go, "Gyatt!" It's like witnessing a mog in real-time, where the sigma energy is so strong it just floors you.
        </p>
      </section>
    </main>
  );
};

export default GooningParksPage;
