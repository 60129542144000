import React from 'react';
import styles from '../styles/RizzidentPage.module.css';

const RizzidentPage: React.FC = () => {
  return (
    <main className={styles.rizzidentPage}>
      <h1 className={styles.title}>Rizzidents of Ohio</h1>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Ohio's Marijuana Programs</h2>
        <p className={styles.text}>
          Ohio's got some major mutt-tier programs for that skunky mew (marijuana), catering to both sigma med and fanum
          rec usage. On the medical edge, Ohio’s dishing out dank for patients who got the right credentials, letting
          them access that sweet relief legally. On the non-med mog side, they’re also paving the way for recreational
          use, ensuring it's regulated so folks can skibidi safely. This dual approach lets Ohioans get their mog on
          whether they need it for health or just to vibe.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Annual Sales Tax Holiday</h2>
        <p className={styles.text}>
          Edge up, fam! Ohio's got that Annual Sales Tax Holiday where you can snag back-to-school gear and other
          goodies without the sales tax hit. For a limited time each year, it’s a sigma-level move to save some cash.
          Get your fanum supplies without the extra mutt cost and keep your wallet happy while you gear up for the
          school season. Skibidi those savings and shop smart!
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Find a Career within the State of Ohio</h2>
        <p className={styles.text}>
          Ohio’s out here mogging the game with skills-based hiring, making it a sigma model employer. Instead of just
          peeping at degrees, they’re focusing on actual skills and experience. This edge approach opens doors for
          talent that might otherwise get overlooked, leveling up the workforce with real capabilities. Ohio's setting
          the fanum standard, showing how to skibidi into a future where skills speak louder than paper credentials. Mew
          right on, Ohio!
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Future Voting Features</h2>
        <p className={styles.text}>
          Stay tuned for upcoming opportunities to vote for Ohio’s future leaders! This page will soon feature details
          on how to cast your vote for Ohio Mayors and other key positions. Make your voice heard and mog the fanum
          democracy grind.
        </p>
      </section>
    </main>
  );
};

export default RizzidentPage;
