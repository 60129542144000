import React from 'react';
import styles from '../styles/VisitorPage.module.css';

const VisitorPage: React.FC = () => {
  return (
    <main className={styles.visitorPage}>
      <h1 className={styles.title}>Tourism</h1>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Ohio Tourism</h2>
        <p className={styles.text}>
          Ready to mew your way through Ohio? Head over to <a href="https://ohio.org" target="_blank" rel="noopener noreferrer" className={styles.link}>Ohio.org</a> for all the fanum deets on attractions, events, lodging, and things to do. Whether you’re into outdoor adventures, cultural hotspots, or just looking to skibidi around some cool spots, Ohio’s got something for everyone. Plan your trip, discover new places, and let Ohio mog your travel expectations!
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>OHGO</h2>
        <p className={styles.text}>
          Need to mew your way through Ohio’s roads? <a href="https://ohgo.com" target="_blank" rel="noopener noreferrer" className={styles.link}>OHGO</a> is your sigma source for real-time traffic updates. Whether you're dodging traffic jams or planning the fastest route, OHGO’s got the deets to help you skibidi smoothly. Stay updated and let OHGO keep your travel game fanum and efficient!
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Museums and Historic Sites</h2>
        <p className={styles.text}>
          Ready to mew into Ohio’s past? Dive into the state’s rich history by exploring its sigma museums and historic sites. Whether you're into art, science, or historical landmarks, Ohio's got the spots to skibidi through time. Discover, learn, and let these fanum places mog your curiosity and appreciation for Ohio's heritage!
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Ohio Byways</h2>
        <p className={styles.text}>
          Mew up for a scenic adventure? Ohio's byways are perfect for delightful day trips and vacations. These sigma routes offer picturesque views, charming towns, and plenty of stops to skibidi along the way. Whether you're cruising for a quick escape or planning a full-on road trip, Ohio's byways will mog your expectations with their beauty and charm. Fanum travels await!
        </p>
      </section>
    </main>
  );
};

export default VisitorPage;
