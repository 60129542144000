import React from 'react';
import styles from '../../styles/DMVPage.module.css';

const DMVPage: React.FC = () => {
  return (
    <main className={styles.dmvPage}>
      <h1 className={styles.title}>Licenses & Permits</h1>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Hunting and Fishing</h2>
        <p className={styles.text}>
          Ready to mew into the wild? You can easily snag your hunting and fishing licenses online in Ohio. Just skibidi over to the website, and you'll be set to mog those outdoor adventures. Whether you're chasing the biggest catch or the perfect hunt, Ohio’s got your fanum licensing needs covered.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>License and Permit</h2>
        <p className={styles.text}>
          Ohio's got the lowdown on all those mew professional licenses and business permits you need to mog the game. Whether you’re starting a business or leveling up your career, you'll need to register with the Ohio Secretary of State for that sigma business license. It's your ticket to legally skibidi and operate in the state. From professionals to entrepreneurs, Ohio's making sure you have all the fanum info to get set up right.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>Driver Licenses</h2>
        <p className={styles.text}>
          Need to mew your way to a driver’s license in Ohio? Whether you're going for a standard license, CDL, or a motorcycle endorsement, Ohio's got the sigma guide on how to get it done. From requirements to testing, they’ve got all the mog info you need to skibidi through the process smoothly. Buckle up and get ready to hit the road with your new fanum license!
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subTitle}>eLicense Ohio</h2>
        <p className={styles.text}>
          Ohio's got that mew digital touch with eLicense Ohio, handling a ton of professional licensing needs. Whether you're looking to mog your way into healthcare, real estate, or any other profession, eLicense Ohio makes it sigma easy to manage your credentials online. Just skibidi through their platform to apply, renew, or check the status of your licenses. It's all about making the process fanum and efficient.
        </p>
      </section>
    </main>
  );
};

export default DMVPage;
